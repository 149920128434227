"use client";

import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import Link from "next/link";
import { useEffect, useState } from "react";
import { getLocalStorageItem, setLocalStorageItem } from "../dashboard/demo";
import { available_Languages, langType } from "../dashboard/state";
import AuthenticationForm from "./loginform";

const LanguagePicker = ({ lang, setLang }: { lang: langType; setLang: (v: langType) => void }) => {
   return (
      <div className=" w-fit">
         <Popover>
            <PopoverTrigger>
               <div className=" text-sm p-2 rounded hover:bg-white hover:text-ailegis-950 h-9 w-9 ">
                  {lang.toUpperCase()}
               </div>
            </PopoverTrigger>
            <PopoverContent side="right" className=" w-fit mb-2">
               <div>
                  {available_Languages.map((l, idx) => {
                     return (
                        <div
                           key={idx}
                           className={cn(
                              " cursor-pointer hover:underline rounded px-2",
                              lang === l.code ? " bg-ailegis-200" : ""
                           )}
                           onClick={() => {
                              setLang(l.code);
                           }}
                        >
                           {l.name}
                        </div>
                     );
                  })}
               </div>
            </PopoverContent>
         </Popover>
      </div>
   );
};

const dictionary = {
   welcomeMessage: {
      en: "KI-powered legal advice for modern legal protection",
      de: "KI-gestützte Rechtsberatung für den modernen Rechtsschutz",
      fr: "Conseil juridique assisté par IA pour la protection juridique moderne",
      it: "Consulenza legale basata sull'IA per la protezione legale moderna",
   },
   loginTitle: {
      en: "Login with existing account",
      de: "Login mit existierendem Account",
      fr: "Connexion avec un compte existant",
      it: "Accedi con un account esistente",
   },
   loginButton: {
      en: "Login",
      de: "Login",
      fr: "Connexion",
      it: "Accesso",
   },
   noAccountText: {
      en: "No account?",
      de: "Kein Konto?",
      fr: "Pas de compte?",
      it: "Nessun account?",
   },
   demoLinkText: {
      en: "Try demo",
      de: "Demo ausprobieren",
      fr: "Essayer la démo",
      it: "Prova la demo",
   },
};

export default function AuthenticationPage() {
   const [lang, setLang] = useState<langType>(() => {
      return getLocalStorageItem("preferredLang", "de");
   });
   const [loginform, setLoginform] = useState(<></>);
   useEffect(() => {
      setLocalStorageItem("preferredLang", lang);
      AuthenticationForm({ text: dictionary.loginButton[lang] }).then((f) => {
         setLoginform(f);
      });
   }, [lang]);
   return (
      <>
         <div className="container relative h-[100vh] flex-col items-center justify-center grid lg:max-w-none lg:grid-cols-2 lg:px-0">
            <div className="relative hidden h-full flex-col bg-muted p-10 text-white lg:flex dark:border-r">
               <div className="absolute inset-0 bg-ailegis-800 " />
               <div className="absolute inset-0 bg-ailegis-950 animate-fade-in " />
               <div className="relative z-20 mt-auto">
                  <blockquote className="space-y-2">
                     <p className="text-lg">{"AILEGIS"}</p>
                     <footer className="text-sm">{dictionary.welcomeMessage[lang]}</footer>
                  </blockquote>
               </div>
            </div>

            <div className="lg:p-8">
               <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
                  <LanguagePicker lang={lang} setLang={setLang} />
                  <div>
                     <div className="flex flex-col space-y-2 text-center">
                        <h1 className="text-2xl font-semibold tracking-tight">{dictionary.loginTitle[lang]}</h1>
                        {loginform}
                        <div className="text-sm">
                           {dictionary.noAccountText[lang]}{" "}
                           <Link href={"/demo"} className="underline">
                              {dictionary.demoLinkText[lang]}
                           </Link>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
}
